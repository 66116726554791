import React from "react";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import get from "lodash/get";

import BaseLayout from "../components/base-layout";
import Container from "../components/container";
import Content from "../components/content";
import PostHeader from "../components/post-header";
import Spacer from "../components/spacer";
import { rhythm } from "../utils/typography";
import { getBodyStyle } from "../utils/styles";
import colors from "../utils/colors";
import { serifFam } from "../utils/fonts";
import presets from "../utils/presets";

const Subtopic = ({ subtopic, ...props }) => (
  <div>
    <Spacer size={2} />
    <h2 css={{ color: colors.white }}>
      <Link css={{ color: colors.white }} to={subtopic.fields.path}>
        {subtopic.frontmatter.name}
      </Link>
    </h2>
    <Spacer size={0.5} />
    <div
      css={{
        display: `grid`,
        gridTemplateColumns: `1fr`,
        gridGap: rhythm(1),
        [presets.Tablet]: { gridTemplateColumns: `2fr 1fr 1fr` },
      }}
    >
      <p css={{ color: colors.white }}>{subtopic.frontmatter.summary}</p>
      {subtopic.frontmatter.topPosts &&
        subtopic.frontmatter.topPosts.map((post, i) => (
          <div
            css={{
              border: `2px solid rgba(255, 255, 255, 0.1)`,
              textAlign: `center`,
            }}
          >
            <Link
              css={{ color: colors.white }}
              to={post.fields.path}
              title={post.frontmatter.summary}
            >
              {post.frontmatter.title}
            </Link>
          </div>
        ))}
    </div>
    <Spacer size={2} />
  </div>
);

const IntroHeading = ({ children }) => {
  return (
    <h3
      css={{
        color: colors.white,
        fontWeight: `100`,
        fontStyle: `italic`,
        opacity: 0.9,
        fontFamily: serifFam,
      }}
    >
      {children}
    </h3>
  );
};

class TopicTemplate extends React.Component {
  render() {
    const topic = this.props.data.topic;
    const allPosts = get(this, "props.data.allPosts.edges", []);
    const subtopics = topic.frontmatter.subtopics;
    return (
      <BaseLayout>
        <Helmet title={topic.frontmatter.heading}>
          <body
            css={{
              ...getBodyStyle(topic.frontmatter.slug),
            }}
          />
        </Helmet>
        <Spacer size={1} />
        <Container>
          <PostHeader
            title={topic.frontmatter.heading}
            subtitle={topic.frontmatter.subheading}
            rhythmWidth={24}
          />
        </Container>
        <Spacer size={2} />
        <Container css={{ gridTemplateColumns: `1fr 1fr`, gridGap: rhythm(4) }}>
          <div>
            <IntroHeading>Welcome!</IntroHeading>
            <Spacer size={1} />
            <Content
              css={{
                color: colors.white,
              }}
              html={topic.html}
            />
            {/*<Img fixed={this.props.data.file.childImageSharp.fixed} />*/}
          </div>
          <div
            css={{
              borderLeft: `1px solid rgba(255,255,255,0.1)`,
              paddingLeft: rhythm(1),
              color: colors.white,
            }}
            html={topic.html}
          >
            <IntroHeading>What will you learn?&hellip;</IntroHeading>
            <Spacer size={1} />
            <ul>
              {topic.frontmatter.contents &&
                topic.frontmatter.contents.map(content => <li>{content}</li>)}
            </ul>
          </div>
        </Container>
        <Spacer size={2} />
        <div
          css={{
            backgroundImage: `linear-gradient(80deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0))`,
            boxShadow: `inset 1px 4px 9px -6px`,
          }}
        >
          {subtopics.map(subtopic => (
            <React.Fragment>
              <Container>
                <Subtopic subtopic={subtopic} />
              </Container>
              <Spacer size={1} />
            </React.Fragment>
          ))}
        </div>
        <div
          css={{
            backgroundImage: `linear-gradient(80deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.15))`,
            boxShadow: `inset 1px 4px 9px -6px`,
          }}
        >
          <Container>
            <Spacer size={2} />
            <h2 css={{ color: `rgba(255, 255, 255, .85)` }}>
              All articles on {topic.frontmatter.name}
            </h2>
            <Spacer size={0.5} />
            <p css={{ color: `rgba(255, 255, 255, .7)` }}>
              Here you'll find a list of all articles in the
              {topic.frontmatter.name} section. A number of these articles were
              my response to questions from people just like you so if you have
              your own, get in touch.
            </p>
            <Spacer size={1} />
            <div
              css={{
                display: `grid`,
                gridTemplateColumns: `1fr`,
                gridGap: rhythm(1),
                [presets.Tablet]: {
                  gridTemplateColumns: `1fr 1fr`,
                },
              }}
            >
              {allPosts.map(post => (
                <div key={post.node.fields.path}>
                  <Link
                    style={{
                      color: `rgba(255, 255, 255, .85)`,
                    }}
                    to={post.node.fields.path}
                  >
                    {post.node.frontmatter.title}
                  </Link>
                </div>
              ))}
            </div>
            <Spacer size={3} />
          </Container>
        </div>
      </BaseLayout>
    );
  }
}

export default TopicTemplate;

export const pageQuery = graphql`
  query TopicBySlug($slug: String!) {
    topic: markdownRemark(
      frontmatter: { slug: { eq: $slug } }
      fields: { sourceInstanceName: { eq: "topics" } }
    ) {
      id
      html
      frontmatter {
        name
        slug
        heading
        subheading
        summary
        contents
        subtopics {
          fields {
            path
          }
          frontmatter {
            name
            slug
            heading
            subheading
            summary
            topPosts {
              fields {
                path
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    }
    allPosts: allMarkdownRemark(
      filter: {
        frontmatter: { draft: { ne: true }, topics: { in: [$slug] } }
        fields: { sourceInstanceName: { eq: "posts" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            path
          }
          frontmatter {
            title
          }
        }
      }
    }
    file(relativePath: { eq: "data/avatars/darian.jpg" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
