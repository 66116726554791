import React from "react";

import Spacer from "../spacer";
import colors from "../../utils/colors";
import { rhythm, scale } from "../../utils/typography";
import { sansFam, serifFam } from "../../utils/fonts";

const PostHeader = ({ title, subtitle, rhythmWidth }) => {
  const splitSubtitle = subtitle.trim().split("\n");
  return (
    <div
      css={{
        display: `flex`,
        alignItems: `flex-start`,
        flexDirection: `column`,
        maxWidth: rhythm(rhythmWidth),
      }}
    >
      <h1
        css={{
          ...scale(1.4),
          fontFamily: serifFam,
          color: colors.white,
        }}
      >
        {title}
      </h1>
      {subtitle && [
        <Spacer size={1} key="1" />,
        <h2
          css={{
            fontWeight: 400,
            fontFamily: sansFam,
            color: colors.white,
            opacity: 0.9,
            ...scale(0.8),
          }}
          key="2"
        >
          {splitSubtitle.map((line, i) => (
            <React.Fragment>
              {line}
              {i !== splitSubtitle.length - 1 && <br />}
            </React.Fragment>
          ))}
        </h2>,
      ]}
    </div>
  );
};

export default PostHeader;
